<template>
  <default-modal
    description="Thank you for your support of GoFindBuild! Our mission is to help your
      business succeed and we will work hard to continue to improve and
      accomplish this goal. Please provide any issues you are experiencing,
      upgrades or features you would like to recommend to us, or additional
      trade categories we could add that will help your business and our team
      will review as soon as possible!"
    :title="title"
    @onConfirm="handleClick(true)"
    @onCancel="handleCancel"
    id="support-modal"
    :showCancel="true"
    confirmLabel="Send"
  >
    <template v-slot:input>
      <div class="mb-3">
        <textarea
          class="form-control"
          id="description"
          placeholder="Description"
          rows="3"
          maxlength="2000"
          v-model="supportText"
        ></textarea>
      </div>
    </template>
  </default-modal>
</template>

<script>
export default {
  name: "public-status-modal",
  props: {
    title: {
      type: String,
      default: "Support",
    },
  },
  data() {
    return {
      supportText: "",
    }
  },
  methods: {
    handleClick() {
      this.$emit("send-support-request", this.supportText)
    },
    handleCancel() {
      this.supportText = ""
      this.$bvModal.hide("support-modal")
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  & .modal-header {
    & .close span {
      font-size: 2.5rem;
    }
  }
}
</style>
