export default {
  trueFalse: [
    { text: "Yes", value: true },
    { text: "No", value: false },
  ],
  yesNo: [
    { text: "Yes", value: "Y" },
    { text: "No", value: "N" },
    { text: "N/A", value: "N/A" },
  ],
  positionOptions: [
    "Internship",
    "Part-Time",
    "Training Apprenticeship",
    "Full-Time",
  ],
  howMuchExperienceOptions: ["< 1YR", "1-5YR", "5-10YR", "10YR+"],
  ratingOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  latestEducationOptions: ["Student", "HS Diploma", "GED", "College Graduate"],
  cdlClassOptions: ["A", "B", "C"],
  veteranStatusOptions: ["Active", "Active Reserve", "Retired"],
  categoryOptions: [
    "Acoustical & Decorative Ceilings",
    "Air Duct cleaning",
    "Alarm Systems",
    "Appliance Repair",
    "Architects",
    "Asbestos Remediation",
    "Asphalt Paving",
    "Awnings",
    "Auto Mechanic",
    "Basement Waterproofing",
    "Bathtub Refinishing",
    "Biohazard Remediation",
    "Boilers & Radiators",
    "Cabinetry Installation",
    "Cabinet Refacing & Refinishing",
    "Carpentry - Custom Woodworking",
    "Carpentry",
    "Carpet Cleaning",
    "Caulking & Sealants",
    "Ceramic or Stone Tile Installation",
    "Clean Energy - Solar Panels",
    "Clean Energy - Wind Turbines",
    "Clean Energy - Bioenergy",
    "Clean Energy - Clean Vehicles",
    "Closet Systems",
    "CNC",
    "Concrete - Flatwork",
    "Concrete - Stamped & Decorative",
    "Concrete - Leveling",
    "Construction Supplies - Sales",
    "Construction Cleaning",
    "Construction Engineering",
    "Construction Management",
    "Construction Estimating",
    "Countertops - Concrete",
    "Countertops - Formica",
    "Countertops - Solid Surface",
    "Countertops - Stone & Marble",
    "Crane Services",
    "Deck Building",
    "Decorative Metals",
    "Demolition - General",
    "Demolition - Licensed",
    "Diesel Mechanic",
    "Doors & Windows",
    "Dryer Vent Cleaning",
    "Drywall Finishes",
    "Dumpsters & Receptacles",
    "EIFS (Exterior Insulation and Finish Systems)",
    "Electrical",
    "Elevators",
    "Equipment & Tool Rental",
    "Erosion Control",
    "Excavating & Earthwork",
    "Fencing",
    "Final Grading",
    "Fire Suppression Systems",
    "Fire Restoration",
    "Fireplace Installation",
    "Flashing & Sheet Metal",
    "Flooring - Carpet",
    "Flooring - Epoxy & Resurfacing",
    "Flooring - Tile",
    "Flooring - Vinyl & LVT",
    "Flooring - Wood & Laminate Wood",
    "Flooring - Wood Refinishing",
    "Forklift Operator",
    "Foundation Repair",
    "Foundation Waterproofing",
    "Foundations - Masonry",
    "Foundations - Poured",
    "Foundations - Slab on Grade",
    "Fountains",
    "Framing - Metal",
    "Framing - Wood",
    "Garage & Overhead Doors",
    "General Labor",
    "Geothermal Systems",
    "Glass Block",
    "Glazing",
    "Groundman",
    "Gutters - Specialty Metals",
    "Gutters & Downspouts",
    "Handyman",
    "Heating & A/C",
    "Heavy Equipment Operator",
    "Highway & Civil Construction",
    "Home Automation & Audio/Visual Wiring",
    "Industrial Hygienist",
    "Insulation",
    "Interior Design & Decorating",
    "Interior Paneling",
    "Ironworkers",
    "Irrigation Systems",
    "Laborer - General",
    "Land Surveying",
    "Landscape Design",
    "Landscaping",
    "Landscaping - Hardscapes",
    "Lead Testing & Remediation",
    "Lineman",
    "Locksmiths",
    "Masonry",
    "Millwright",
    "Mold Testing & Remediation",
    "Mulch & Topsoil",
    "Ornamental Metals & Fabrication",
    "Painting",
    "Painting & Staining - Custom Finishes",
    "Permitting Services",
    "Plastering",
    "Plumbing & Pipefitting",
    "Pool Construction",
    "Portable Restrooms",
    "Pressure Washing",
    "Punch & Close out",
    "Radon Systems",
    "Radon Testing",
    "Rigging",
    "Roof Cleaning",
    "Roofing - Asphalt",
    "Roofing - Membrane",
    "Roofing - Metal",
    "Roofing - Slate",
    "Roofing - Tile",
    "Scaffolding",
    "Septic Systems",
    "Sewer & Drain Cleaning",
    "Shower Doors & Glass Panels",
    "Siding - Cement",
    "Siding - Vinyl & Metal",
    "Siding - Wood",
    "Skylights",
    "Sodding & Seed Installation",
    "Stainless Steel Fabrication",
    "Stair Railing Systems",
    "Steel Erection",
    "Structural Engineering",
    "Structural Metals",
    "Structural Glazing",
    "Stucco Finishes",
    "Tool & Equipment Rental",
    "Tree Service",
    "Trucking & Hauling",
    "Wallpapering",
    "Warehouse Work",
    "Water & Smoke Remediation",
    "Welding",
    "Wells & Pumps",
    "Window Treatments",
  ],
  stateOptions: [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "DC", abbreviation: "DC" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Puerto Rico", abbreviation: "PR" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" },
  ],
  workRadiusOptions: ["0-50mi", "0-100mi", "0-200mi+"],
  industryOptions: ["Residential", "Commercial", "Industrial", "Civil/Highway"],
  numberOfEmployeesOptions: ["1 - 5", "5 - 20", "20 - 50", "50 - 100", "100+"],
  paymentProcessTimeOptions: ["Net 15", "Net 30", "Net 60", "Net 90+"],
  designationsOptions: [
    "Minority (MBE)",
    "Veteran (VBE)",
    "Women (WBE)",
    "Disability (DOBE)",
  ],
  volumeCompletedProjectsOptions: ["$0-$1M", "$1M-$5M", "$5M-$25M", "$25M+"],
  laborOptions: ["Subcontracted Labor", "Employee Labor", "Both"],
  jobBenefitsOptions: ["PTO", "Bonus", "401K", "Other"],
  managementExperienceOptions: [
    "Crew Management",
    "Site Management",
    "Project Management",
  ],
  tradeCategoryExperienceOptions: ["<1YR", "1-5YR", "5-10YR", "10YR+"],
  oshaCertificationOptions: ["No", "10-hour", "30-hour"],
  subSectionIconOptions: {
    "education-icon": "PhGraduationCap",
    "transportation-icon": "PhCar",
    "trade-experience-icon": "PhWrench",
    "looking-for-icon": "PhEye",
    "work-history-icon": "PhHourglassHigh",
    "qualified-designation": "PhStar",
    affiliations: "PhUsersThree",
    "operational-details-icon": "PhGear",
    "benefits-icon": "PhGift",
    "qualifications-icon": "PhGraduationCap",
    "compensation-icon": "PhCurrencyDollar",
    "description-icon": "PhNote",
  },
  successCenterIconOptions: {
    info: "PhInfo",
    warning: "PhWarning",
    urgent: "PhBell",
  },
}
