<template>
  <div class="row sub-section-title-wrapper">
    <template v-if="!isEditing">
      <component v-bind:is="subSectionIconOptions[icon]"></component>
      <div class="col sub-section-title">
        <h2>{{ title }}</h2>
        <div class="edit-btn" v-if="showEdit" @click="$emit('toggle-edit')">
          <PhPencilSimple color="#0095E2" :size="20" :weight="'bold'" />
          Edit
        </div>
      </div>
    </template>
    <template v-else>
      <div class="edit-btn" @click="$emit('add-new')">
        <PhPlus color="#0095E2" :size="20" :weight="'bold'" />
        {{ addNewLabel }}
      </div>
      <div class="action-btns">
        <default-button
          :btnType="['cancel-edit-btn']"
          :labelText="'Cancel'"
          @click="$emit('toggle-edit')"
        />
        <default-button :labelText="'Save'" @click="$emit('save')" />
      </div>
    </template>
  </div>
</template>

<script>
import options from "@/utils/options"
import {
  PhGraduationCap,
  PhCar,
  PhWrench,
  PhEye,
  PhHourglassHigh,
  PhStar,
  PhUsersThree,
  PhGear,
  PhPiggyBank,
  PhCertificate,
  PhCurrencyDollar,
  PhPencilSimple,
  PhPlus,
  PhNote,
  PhGift,
} from "phosphor-vue"

export default {
  components: {
    PhGraduationCap,
    PhCar,
    PhWrench,
    PhEye,
    PhHourglassHigh,
    PhStar,
    PhUsersThree,
    PhGear,
    PhPiggyBank,
    PhCertificate,
    PhCurrencyDollar,
    PhPencilSimple,
    PhPlus,
    PhNote,
    PhGift,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    addNewLabel: {
      type: String,
      required: false,
      default: "Add New",
    },
  },
  data() {
    return {
      subSectionIconOptions: options.subSectionIconOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.sub-section-title-wrapper {
  border-bottom: 1px solid var(--Gray-200, #d5d9e2);
  width: 100%;
  margin-top: 32px !important;
  margin-bottom: 16px !important;
  display: flex;
  align-items: center;

  svg {
    height: 32px;
    width: 32px;
    color: var(--gfb-orange-brand-400, #ff9921);
    margin-right: 10px;
  }
}
.sub-section-title {
  display: flex;
  padding: 8px 0px 8px 4px;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
}
.sub-section-title h2 {
  color: var(--Gray-800, #3a4252);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

.action-btns {
  display: flex;
  margin-right: 0;
  margin-left: auto;
}

.edit-btn {
  display: flex;
  padding: 10px 24px 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 2px solid var(--gfb-blue-brand-500, #0095e2);
  background: var(--White, #fff);
  font-size: 14px;
  color: var(--gfb-blue-brand-500, #0095e2);
  font-weight: 600;
  cursor: pointer;

  svg {
    height: 20px;
    width: 20px;
  }
}
</style>
