import InnerLayout from "@/layout/InnerLayout"
import store from "@/store/store.js"

const navItems = [
  {
    title: "Projects",
    to: "/trade-partners/projects",
  },
  {
    title: "Search Trade Partners",
    to: "/trade-partners/search",
  },
  {
    title: "Search Projects",
    to: "/trade-partners/search-projects",
  },
]

export const tradePartnersRouter = [
  {
    path: "/trade-partners",
    redirect: "/trade-partners/projects",
    component: InnerLayout,
    props: { navItems },
    children: [
      {
        path: "projects",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/TradePartners/Projects.vue"
          ),
      },
      {
        path: "projects/new",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/TradePartners/ProjectsEdit.vue"
          ),
      },
      {
        path: "projects/:projectId",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/TradePartners/ProjectDetail.vue"
          ),
        beforeEnter: (to, from, next) => {
          const userType = store.state.userProfile.type

          let payload = {
            projectId: to.params.projectId,
            companyId: store.state.userProfile._id,
            userType: userType,
            activityType: "view-project-details",
          }
          store.dispatch("api/createLog", payload)

          next()
        },
      },
      {
        path: "projects/:projectId/edit",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/TradePartners/ProjectsEdit.vue"
          ),
      },
      {
        path: "projects/:projectId/matches",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/TradePartners/ProjectMatches.vue"
          ),
      },
      {
        path: "projects/:projectId/matches/:profileId",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/TradePartners/ProjectMatchProfileDetail.vue"
          ),
      },
      {
        path: "search",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/TradePartners/Search.vue"
          ),
      },
      {
        path: "search/profile/:profileId",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/TradePartners/SearchResultProfileDetail.vue"
          ),
      },
      {
        path: "search-projects",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/TradePartners/SearchProjects.vue"
          ),
      },
      {
        path: "search-projects/:projectId",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/TradePartners/SearchProjectsDetail.vue"
          ),
      },
      {
        path: "search-projects/:projectId/profile/:profileId",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/TradePartners/SearchProjectsProfileDetail.vue"
          ),
      },
    ],
  },
]
