<template>
  <div class="job-section">
    <inner-layout-action-bar-wrapper>
      <!--template v-if="!job" v-slot:actions-left>&nbsp;</template>
    <template v-else-if="job.published" v-slot:actions-left
      ><span class="mr-1">
        Post an available job and attract job seekers to respond directly to
        your opportunity. Find your next employee today!
      </span></template
    >
    <template v-else-if="paymentIntent" v-slot:actions-left>
      Complete payment to post your job!
    </template>
    <template v-else-if="!job.briefing" v-slot:actions-left
      ><span class="mr-1">
        This job is not yet published because you have not completed payment for
        it.
      </span></template
    -->
      <template v-if="!job" v-slot:actions-right>&nbsp;</template>
      <JobDetailHeader :isPreview="true" :job="job" />
      <!--template v-else-if="!job.briefing" v-slot:actions-right>
      <base-button
        type="success"
        @click="handleJobPayment"
        class="rounded-lg"
        :loading="loading"
        >Purchase</base-button
      >
    </template-->
      <div
        v-if="!dataRetrieving && !job"
        class="m-4 w-100 d-flex justify-center"
      >
        We couldn't find this job in our records.
      </div>
      <div
        v-else-if="job && (job.published || !paymentIntent)"
        class="responsive-width py-4 mx-auto"
      >
        <job-view :job="job" />
      </div>
    </inner-layout-action-bar-wrapper>
  </div>
</template>

<script>
import { nanoid } from "nanoid"
import job from "@/api/jobs"
import JobView from "@/components/Job/JobView"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import messagesMixin from "@/mixins/messagesMixin"
import paymentsMixin from "@/mixins/paymentsMixin"
import { STRIPE_PRODUCTS } from "@/utils/constants"
import JobDetailHeader from "@/components/Job/JobDetailHeader"

export default {
  components: {
    InnerLayoutActionBarWrapper,
    JobView,
    JobDetailHeader,
  },
  mixins: [messagesMixin, paymentsMixin],
  data() {
    const paymentIntent = this.$router.currentRoute.query.payment_intent
    const jobId = this.$router.currentRoute.params?.jobId
    const isCheckoutCompletePage =
      this.$router.currentRoute.path.includes("checkout-complete")
    return {
      job: null,
      productSlug: STRIPE_PRODUCTS.JOB_POSTING,
      product: null,
      paymentIntent,
      loading: false,
      dataRetrieving: false,
      returnUrlPath: `/employees/jobs/${jobId}/checkout-complete`,
      isCheckoutCompletePage,
    }
  },
  async created() {
    this.dataRetrieving = true
    await this.getJob()
    this.dataRetrieving = false
  },
  methods: {
    async getJob() {
      const jobId = this.$router.currentRoute.params?.jobId
      if (jobId) {
        const { data } = await job.getJobDetails(jobId)
        if (!data || data.errors) {
          this.showErrorMessage(
            data.errors[1],
            "We couldn't find data for this job."
          )
        } else {
          this.job = data
        }
      }
    },
    async handleConfirm() {
      await this.$refs.productPurchase.handleConfirm()
    },
    async handleJobPayment() {
      this.loading = true
      const job_id = this.$router.currentRoute.params?.jobId
      const jobUrl = `/employees/jobs/${job_id}`
      const paymentIntentData = {
        job_id,
        product_slug: STRIPE_PRODUCTS.JOB_POSTING,
        return_url: `${jobUrl}/checkout-complete`,
        idempotencyKey: nanoid(),
      }

      const response = await this.$store.dispatch(
        "api/createPaymentIntent",
        paymentIntentData
      )

      const defaultErrorMessage = "We couldn't submit your payment request."
      if (!response.data || response.data.errors) {
        throw new Error(response.data?.errors ?? defaultErrorMessage)
      }
      const paymentIntent = response.data
      if (paymentIntent) {
        this.$router.replace(`${jobUrl}?payment_intent=${paymentIntent.id}`)
      } else {
        throw new Error(defaultErrorMessage)
      }
    },
    async handleDeleteJob() {
      try {
        await job.deleteJob(this.$router.currentRoute.params?.jobId)
        this.$toast.success("Job has been deleted successfully")
        this.$router.push(`/employees/jobs`)
      } catch (error) {
        this.$toast.error(error.message || "We could not delete this job.")
      }
    },
    setLoading(loading) {
      this.loading = loading
    },
    setDataRetrieving(isRetrieving) {
      this.dataRetrieving = isRetrieving
    },
  },
}
</script>

<style lang="scss" scoped>
.responsive-width {
  width: 80%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
</style>
