<template>
  <div class="row job-details" data-app>
    <StatusDialog
      :show="showApplySuccess"
      :type="'success'"
      :heading="'Successful'"
      :buttonLabel="'Back to Search'"
      :description="`You have successfully applied to ${job.name}`"
      @continue="goToSearch"
    />
    <RedirectLink v-if="isPreview" :to="'/employees/jobs'" />
    <RedirectLink v-else />
    <div class="contact-info-wrapper">
      <div class="contact-info">
        <div class="profile-img">
          <img src="/img/icons/mask-group.svg" alt="" />
        </div>
        <div class="contact-info-details">
          <h3>
            {{ job.name }}
            <PhStar
              @click="handleSaveJob"
              size="24"
              color="#FFB14A"
              weight="fill"
              v-if="
                !isPreview &&
                $store.state.userProfile.savedJobs.includes(job._id)
              "
            />
            <PhStar @click="handleSaveJob" size="24" color="#B0B9C9" v-else />
          </h3>
          <span v-if="!isPreview">{{ job.profile[0].name }}</span>
          <span v-if="isPreview">{{ $store.state.userProfile.name }}</span>
          <span
            @click="goToCompanyProfile(job.profile[0]._id)"
            class="view-profile-link"
            v-if="!isPreview"
            >View company profile</span
          >
          <span class="location"
            ><PhMapPin weight="fill" size="24px" />{{ job.city }},
            {{ job.state }}</span
          >
          <span>
            <icon
              icon="calendar"
              width="20"
              height="20"
              color="black"
              class="mr-2"
            />Posted on:
            {{ $dayjs(job.createdAt).format("MM/DD/YYYY") }}
          </span>
        </div>
      </div>
      <div class="apply-block" v-if="isPreview">
        <div class="info-text">Edit this job</div>
        <DefaultButton
          v-if="!job._id"
          btnClass="apply-btn"
          :labelText="`Edit`"
          @click="$emit('set-current-view')"
          :disabled="loading"
        />
        <DefaultButton
          v-else
          btnClass="apply-btn"
          :labelText="'Edit'"
          @click="$router.push(`/employees/jobs/${job && job._id}/edit`)"
        />
      </div>
      <div class="apply-block" v-else>
        <div class="info-text">
          {{
            alreadyApplied
              ? "You’ve already applied to this job"
              : "Interested in this job? Apply now!"
          }}
        </div>
        <div v-if="alreadyAppliedDate" class="applied-date">
          {{ $dayjs(alreadyAppliedDate).format("MMM DD, YYYY") }}
        </div>
        <DefaultButton
          btnClass="apply-btn"
          labelText="Apply"
          :disabled="alreadyApplied"
          @click="handleApplyForJob"
        />
      </div>
    </div>
    <div>
      <div v-if="this.$store.state.userProfile.type == 'jobSeeker'">
        <div class="action-btns" v-if="job.briefing">
          <b-button
            variant="danger"
            @click="handleDeclineJob"
            class="mr-4 rounded-lg"
            >Decline</b-button
          >
          <b-button
            variant="success"
            @click="handleAcceptJob"
            class="rounded-lg"
            >I'm Interested</b-button
          >
        </div>
      </div>
      <template v-else>
        <div class="action-btns" v-if="!isPreview">
          <confirmation-modal
            buttonText="Discard"
            description="Are you sure you want to delete this job?"
            title="Delete Job"
            @onConfirm="handleDeleteJob()"
          ></confirmation-modal>
          <DefaultButton
            :labelText="'Edit'"
            @click="$router.push(`/employees/jobs/${job && job._id}/edit`)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import DefaultButton from "@/components/DefaultButton"
import RedirectLink from "@/components/RedirectLink"
import { PhMapPin, PhStar } from "phosphor-vue"
import job from "@/api/jobs"
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue"
import profile from "@/api/profile"
import StatusDialog from "@/components/Modals/StatusDialog.vue"
export default {
  components: {
    DefaultButton,
    PhMapPin,
    ConfirmationModal,
    StatusDialog,
    RedirectLink,
    PhStar,
  },
  data() {
    return {
      showApplySuccess: false,
    }
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
    alreadyApplied: {
      type: Boolean,
      required: false,
    },
    alreadyAppliedDate: {
      type: String,
      required: false,
    },
    isPreview: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    async handleDeleteJob() {
      try {
        await job.deleteJob(this.$router.currentRoute.params?.jobId)
        this.$toast.success("Job has been deleted successfully")
        this.$router.push(`/employees/jobs`)
      } catch (error) {
        this.$toast.error(error.message || "We could not delete this job.")
      }
    },
    async handleSaveJob() {
      try {
        let userProfile = JSON.parse(
          JSON.stringify(this.$store.state.userProfile)
        )
        if (userProfile.savedJobs) {
          if (userProfile.savedJobs.includes(this.job._id)) {
            let index = userProfile.savedJobs.indexOf(this.job._id)
            userProfile.savedJobs.splice(index, 1)
          } else {
            userProfile.savedJobs.push(this.job._id)
          }
        } else {
          userProfile.savedJobs = [this.job._id]
        }
        let { data } = await this.$store.dispatch("api/updateProfile", {
          profileId: userProfile._id,
          data: { ...userProfile },
        })

        await this.$store.commit("UPDATE_USER_PROFILE", data)
      } catch (error) {
        console.log("error", error)
        this.logDevError(error)
      }
    },
    goToSearch() {
      this.$router.push("/jobs/search")
    },
    async handleApplyForJob() {
      let recipientProfileId = this.job.profile[0]._id
      let id = this.$router.currentRoute.params?.jobId
      let type = "job-application"
      let data = await profile.requestProfileAccess(
        recipientProfileId,
        id,
        type
      )
      console.log("DATA", data)
      await this.$store
        .dispatch("api/getUserProfile", {
          userId: this.$store.state.user._id,
        })
        .then(() => {
          this.showApplySuccess = true
        })
    },
    handleDeclineJob() {
      this.declineJob = true
    },
    goToCompanyProfile(id) {
      this.$router.push({
        name: "profile-id",
        params: { profileId: id, type: "provider" },
      })
    },
    async handleAcceptJob() {
      let id = this.$router.currentRoute.params.jobId
      let type = "job"
      await profile.grantProfileAccess(this.company._id, id, type)
      await this.$store.dispatch("api/getUserProfile", {
        userId: this.$store.state.user._id,
      })
      this.$router.push("/dashboard")
    },
  },
}
</script>

<style lang="scss" scoped>
.job-details {
  display: flex;
  padding: 24px 96px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: var(--Gray-50, #f6f7f9);
  margin: 0;
  margin-bottom: 30px;
  position: relative;

  .contact-info-wrapper {
    display: flex;
    width: 100%;
    margin-top: 24px;

    .contact-info {
      display: flex;
      width: 100%;
      align-items: center;

      .profile-img {
        width: 104px;
        height: 104px;
        fill: var(--Gray-100, #eceef2);
        border-radius: 50%;
        margin-right: 32px;

        img {
          border-radius: 50%;
        }
      }

      .contact-info-details {
        display: flex;
        flex-direction: column;

        h3 {
          margin-bottom: 6px;
          font-size: 24px;
          font-weight: 700;
          display: flex;
          gap: 12px;

          svg {
            cursor: pointer;
          }
        }

        span {
          margin-top: 12px;
          font-size: 14px;
          display: flex;

          svg {
            margin-right: 5px;
          }
        }

        .view-profile-link {
          color: var(--gfb-blue-brand-500, #0095e2);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          cursor: pointer;
        }

        .location {
          color: var(--Gray-600, #525e77);
        }
      }
    }
  }

  .action-btns {
    display: flex;
    position: absolute;
    right: 24px;
    top: 24px;
  }
}

.apply-block {
  display: flex;
  padding: 20px 24px 24px 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #d5d9e2);
  background: var(--White, #fff);
  min-width: 350px;
  justify-content: center;

  .info-text {
    color: var(--Gray-900, #343946);
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }

  .applied-date {
    color: var(--Gray-600, #525e77);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }

  .button-wrapper {
    width: 100% !important;

    input[type="button"] {
      width: 100% !important;
      margin: 0 !important;
    }
  }
}
</style>
