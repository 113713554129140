<template>
  <div>
    <b-navbar id="topnav" toggleable type="dark" variant="dark">
      <b-container fluid>
        <b-row class="align-items-start">
          <b-col cols="12" class="d-flex justify-content-end">
            <b-navbar-brand href="#">
              <img class="nav-icon" :src="`/img/icons/gfb-logo-large.svg`" />
            </b-navbar-brand>

            <b-navbar-toggle
              target="navbar-toggle-collapse"
              style="margin-left: auto"
            ></b-navbar-toggle>
          </b-col>

          <b-collapse id="navbar-toggle-collapse">
            <b-navbar-nav class="ml-auto">
              <b-nav-item
                v-for="item in this.sidebarItems"
                :key="item.title"
                :class="{ 'current-route': (($route.path === item.to) || (Array.isArray(item.children) && item.children.includes($route.path)))
                }"                
                class="nav-link mini-list-item"
                @click="$router.push(item.to)"
              >
                <component size="24" v-bind:is="item.icon"></component>
                <i :class="item.icon" class="mr-2"></i>
                {{ item.title }}
              </b-nav-item>
              <b-nav-item
                :key="'Support'"
                class="nav-link mini-list-item"
                @click="handleSupportModal()"
              >
                <img
                  class="nav-icon mr-2"
                  :src="`/img/icons/Support 1 1.svg`"
                />
                Support
              </b-nav-item>

              <v-list-group
                class="mini-list-group-item nav-link"
                :class="{ 'current-route': $route.path == '/toolbox/settings' || $route.path == '/toolbox/billing' }"
                v-if="profileType == 'company'"
                :key="companyItems.title"
                v-model="companyItems.active"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-icon>
                    <component
                      size="24"
                      v-bind:is="companyItems.icon"
                    ></component>
                    <span class="tooltipText" v-if="mini">{{
                      companyItems.title
                    }}</span>
                  </v-list-item-icon>
                  <v-list-item-content class="ml-2">
                    <v-list-item-title
                      v-text="companyItems.title"
                    ></v-list-item-title>
                    <v-list-item-subtitle>{{
                      profileType
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>

                <v-list-item
                  class="nav-link mini-list-item"
                  :class="{ 'current-route': $route.path == child.to }"
                  @click="$router.push(child.to)"
                  v-for="child in companyItems.items"
                  :key="child.title"
                >
                  <v-list-item-icon>
                    <component size="24" v-bind:is="child.icon"></component>
                    <span class="tooltipText" v-if="mini">{{
                      child.title
                    }}</span>
                  </v-list-item-icon>
                  <v-list-item-content class="ml-2">
                    <v-list-item-title v-text="child.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>

              <v-list-group
                class="mini-list-group-item nav-link"
                :class="{ 'current-route': $route.path == '/toolbox/settings' || $route.path == '/toolbox/billing' }"
                v-if="profileType == 'jobSeeker'"
                :key="jobSeekerItems.title"
                v-model="jobSeekerItems.active"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-icon>
                    <component
                      size="24"
                      v-bind:is="jobSeekerItems.icon"
                    ></component>
                    <span class="tooltipText" v-if="mini">{{
                      jobSeekerItems.title
                    }}</span>
                  </v-list-item-icon>
                  <v-list-item-content class="ml-2">
                    <v-list-item-title
                      v-text="jobSeekerItems.title"
                    ></v-list-item-title>
                    <v-list-item-subtitle>Job Seeker</v-list-item-subtitle>
                  </v-list-item-content>
                </template>

                <v-list-item
                  class="nav-link mini-list-item"
                  :class="{ 'current-route': $route.path == child.to }"
                  @click="$router.push(child.to)"
                  v-for="child in jobSeekerItems.items"
                  :key="child.title"
                >
                  <v-list-item-icon>
                    <component size="24" v-bind:is="child.icon"></component>
                    <span class="tooltipText" v-if="mini">{{
                      child.title
                    }}</span>
                  </v-list-item-icon>
                  <v-list-item-content class="ml-2">
                    <v-list-item-title v-text="child.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>

              <b-nav-item
                :key="'Logout'"
                class="nav-link mini-list-item"
                @click="handleLogout()"
              >
                <PhSignOut class="mr-2" size="24" />
                Logout
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-row>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
import messagesMixin from "@/mixins/messagesMixin"
import SupportModal from "@/components/Modals/SupportModal"
import {
  PhUser,
  PhHandshake,
  PhHouse,
  PhUsers,
  PhBriefcase,
  PhMagnifyingGlass,
  PhSignOut,
  PhGear,
  PhCurrencyDollar,
  PhUserCircle,
} from "phosphor-vue"
export default {
  mixins: [messagesMixin],
  components: {
    SupportModal,
    PhUser,
    PhHandshake,
    PhUsers,
    PhHouse,
    PhBriefcase,
    PhMagnifyingGlass,
    PhSignOut,
    PhGear,
    PhCurrencyDollar,
    PhUserCircle,
  },
  props: {
    sidebarItems: {
      type: Array,
    },
  },
  data() {
    const userProfile = this.$store.state.userProfile
    const profileType = userProfile?.jobSeeker ? "jobSeeker" : "company"
    const companyItems = {
      icon: "PhUserCircle",
      active: false,
      items: [
        { title: "Settings", icon: "PhGear", to: "/toolbox/settings" },
        { title: "Billing", icon: "PhCurrencyDollar", to: "/toolbox/billing" },
      ],
      title: userProfile.name,
    }
    const jobSeekerItems = {
      icon: "PhUserCircle",
      active: false,
      items: [{ title: "Settings", icon: "PhGear", to: "/toolbox/settings" }],
      title: userProfile.name,
    }
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      selected: this.$store.state.userProfile.public,
      prof: this.$store.state.userProfile ?? null,
      profileType,
      jobSeekerItems,
      companyItems,
    }
  },
  computed: {
    profile() {
      return this.$store.state.userProfile
    },
    initials() {
      const { name } = this.$store.state.userProfile
      const names = name.split(" ")
      const firstName = names[0]
      const lastName = names.length > 1 ? names.slice(-1)[0] : null
      const initials =
        firstName.charAt(0) + (lastName ? lastName.charAt(0) : "")
      return initials
    },
    jobApplications() {
      return this.$store.state.userProfile.profilesRequested.filter(
        (i) => i.type == "job-application"
      )
    },
    jobBriefings() {
      return this.$store.state.userProfile.profilesRequested.filter(
        (i) => i.type == "job"
      )
    },
  },
  created() {
    this.updateProfile()
  },
  watch: {
    "$store.state.userProfile": {
      handler(newValue) {
        this.prof = newValue
      },
      deep: true,
    },
  },
  methods: {
    async updateProfile() {
      const type = this.$store.state.userProfile.type
      await this.$store.dispatch(
        `api/${
          type == "jobSeeker" ? "getUserProfile" : "getCompanyUserProfile"
        }`,
        {
          userId: this.$store.state.user._id,
        }
      )
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    handleLogout() {
      try {
        this.$store.dispatch("auth/revokeToken")
        localStorage.clear()
        this.$router.push("/login")
      } catch (error) {
        this.showErrorMessage(error, "We couldn't revoke your credentials.")
      }
    },
    handleSupportModal() {
      this.$bvModal.show("support-modal")
    },
    async sendSupportRequest(data) {
      try {
        const profile = this.$store.state.userProfile
        const { id, type } = profile
        await this.$store.dispatch(
          `api/${type == "jobSeeker" ? "sendSupport" : "sendCompanySupport"}`,
          {
            data,
            profileId: id,
          }
        )
        this.$bvModal.hide("support-modal")
        this.$toast.success("Support request sent!")
      } catch (e) {
        this.$toast.error("Error sending support request")
      }
    },
  },
}
</script>

<style lang="scss">
.v-list-group__items {
  .nav-link {
    display: flex !important;
  }
}

.v-list-item__title {
  font-size: 18px !important;
}

.mini-list-group-item.nav-link .v-list-item__icon svg {
  margin-left: 0px !important;
}

.avatar-icon {
  font-family: var(--font-family-base);
  font-size: 3rem;
  font-weight: bold;
  width: 100%;
  height: 100%;
}
.b-dropdown {
  background: white !important;
  border-radius: 14px !important;
  border: 1px solid #273057;
  .dropdown-toggle {
    background: white !important;
    border-radius: 14px !important;
  }
}

.nav-link.current-route {
  background: var(--gfb-blue-brand-70050-opacity, rgba(0, 82, 124, 0.5));
  border-radius: 8px !important;
}

@media (max-width: 767.98px) {
  #topnav {
    background: var(--dark-bg) !important;
  }
  #topnav .nav-link {
    padding: 0.625rem 0;
    color: #ffffff !important;
  }
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    position: relative;
    background: var(--dark-bg) !important;
  }
  #navbar-main {
    display: none;
  }
}
@media (min-width: 767.99px) {
  #topnav {
    display: none;
  }
}
</style>
