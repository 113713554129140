<template>
  <b-form-group
    :label="label"
    :class="[
      'gfb-date-picker',
      classes,
      { 'has-danger': error },
      { 'has-success': valid === true },
      { 'has-danger': valid === false },
      { disabled: !!disabled },
    ]"
  >
    <button
      type="button"
      aria-haspopup="dialog"
      aria-expanded="false"
      class="btn h-auto"
      id="__BVID__118"
    >
      <svg
        viewBox="0 0 16 16"
        width="1em"
        height="1em"
        focusable="false"
        role="img"
        aria-label="calendar"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="bi-calendar b-icon bi"
      >
        <g>
          <path
            d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
          ></path>
        </g>
      </svg>
    </button>
    <date-picker
      :value="value"
      v-on="$listeners"
      v-bind="$attrs"
      :state="valid"
      :disabled="disabled"
      :config="options"
    ></date-picker>
    <form-error nonInput v-show="error">{{ error }}</form-error>
  </b-form-group>
</template>
<script>
import { isNullish } from "@/utils/validations"
import datePicker from "vue-bootstrap-datetimepicker"
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css"

export default {
  inheritAttrs: false,
  name: "yearly-date-picker",
  components: {
    datePicker,
  },
  props: {
    error: String,
    classes: {
      type: String,
      description: "String classes to be applied to the root element",
      default() {
        return ""
      },
    },
    label: String,
    value: {
      type: String,
      description: "Input value",
    },
    disabled: Boolean,
  },
  computed: {
    valid() {
      return isNullish(this.error) ? null : !!this.error
    },
  },
  data() {
    return {
      options: {
        format: "YYYY",
        useCurrent: true,
        viewMode: "years",
      },
    }
  },
}
</script>
<style lang="scss" global>
.form-group.gfb-date-picker button {
  position: absolute;
  right: 10px;
  z-index: 10;
  cursor: pointer;
}

.form-group.gfb-date-picker .bootstrap-datetimepicker-widget {
  z-index: 99999;
}

.form-group.gfb-date-picker input {
  border: 1px solid var(--Gray-300, #b0b9c9);
  border-radius: 8px;
  display: flex;
  align-items: center;
  height: auto;
  position: relative;
  z-index: 9999;
  background: #fff;
  cursor: pointer;
  padding: 0.625rem 0.75rem;

  &:hover {
    border: 2px solid var(--Gray-500, #677690);
  }

  & .btn {
    padding: 0 0.5rem;

    &:hover {
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  & label {
    border: none;
    margin-bottom: 0;
    padding: 0;
    line-height: 1.8;
    height: unset;
  }
}

.form-group.gfb-date-picker.disabled input {
  background-color: #eee;
}
</style>
