<template>
  <div v-if="!!user && !!profile">
    <JobseekerProfileHeader
      :applicant="companyJobseekerView"
      :hasProfileViewAccess="hasAccessToProfile"
      :profile="profile"
      :isEditProfile="isEditProfile"
      v-on="$listeners"
    />
    <CompanyProfileHeader
      :profile="profile"
      :jobseekerCompanyView="jobseekerCompanyView"
    />
    <div class="container-fluid user-profile">
      <div class="row justify-content-center">
        <div class="col-lg-12" v-if="profile">
          <profile-header
            v-if="!hide"
            :profile="profile"
            :isEditing="isEditing"
            @handle-click="handleEditClick"
            @handle-complete="fetchProfile"
          />
          <div v-if="profile.jobSeeker">
            <candidate-profile-overview
              :profile="profile"
              :isEditing="isEditing"
              :applicant="applicant"
              @handle-click="handleEditClick('overview')"
            />

            <candidate-profile-status
              :profile="profile"
              :isEditing="isEditing"
              @handle-click="handleEditClick('status')"
            />

            <div class="pt-3" v-if="shouldDisplayHandsOnExperience">
              <candidate-profile-experience
                :isEditing="isEditing"
                @handle-click="handleEditClick('experience')"
                @on-experiences-change="handleExperiencesChange"
                :experiences="profile.jobSeeker.handsOnExperiences"
                :profile="profile"
                :experienceDescription="
                  profile.jobSeeker.handsOnExperienceDescription
                "
              />
            </div>

            <candidate-profile-work
              :profile="profile"
              :isEditing="isEditing"
              @handle-click="handleEditClick('work')"
            />

            <candidate-profile-references
              :profile="profile"
              :isEditing="isEditing"
              @handle-click="handleEditClick('references')"
            />

            <div class="row" v-if="isEditing || profile.jobSeeker.resume">
              <div class="col">
                <signed-file-download-button
                  v-if="profile.jobSeeker.resume"
                  :btnLabel="'Open Resume'"
                  :file="profile.jobSeeker.resume"
                  :fileName="`${profile.jobSeeker.name}_resume.pdf`"
                />
                <h2
                  class="font-22-900 dark-text mb-4"
                  v-if="isEditing && !profile.jobSeeker.resume"
                >
                  Upload Resume
                </h2>
              </div>
            </div>
          </div>
          <div class="row mt-5" v-if="profile.jobSeeker">
            Last Activity: {{ lastActivity }}
          </div>

          <company-profile-overview
            v-if="!profile.jobSeeker && !profile.educational"
            :jobseekerCompanyView="jobseekerCompanyView"
            :profile="profile"
          />

          <CompanyProfileJobsList
            v-if="
              !profile.jobSeeker && !profile.educational && jobseekerCompanyView
            "
            :profile="profile"
          />
        </div>
      </div>
    </div>
    <toast-message ref="toastMessage" />
  </div>
</template>
<script>
import { saveAs } from "file-saver"
import CandidateProfileExperience from "@/components/Profile/Candidate/CandidateProfileExperience"
import CandidateProfileOverview from "@/components/Profile/Candidate/CandidateProfileOverview"
import CandidateProfileReferences from "@/components/Profile/Candidate/CandidateProfileReferences"
import CandidateProfileStatus from "@/components/Profile/Candidate/CandidateProfileStatus"
import CompanyProfileOverview from "@/components/Profile/Company/CompanyProfileOverview"
import CandidateProfileWork from "@/components/Profile/Candidate/CandidateProfileWork"
import ProfileHeader from "@/components/Profile/ProfileHeader"
import messagesMixin from "@/mixins/messagesMixin"
import moment from "moment"
import JobseekerProfileHeader from "@/components/Profile/JobseekerProfileHeader.vue"
import CompanyProfileHeader from "@/components/Profile/CompanyProfileHeader.vue"
import CompanyProfileJobsList from "@/components/Profile/Company/CompanyProfileJobsList"

export default {
  components: {
    CandidateProfileExperience,
    CandidateProfileOverview,
    CandidateProfileStatus,
    CompanyProfileOverview,
    CandidateProfileReferences,
    CandidateProfileWork,
    ProfileHeader,
    JobseekerProfileHeader,
    CompanyProfileHeader,
    CompanyProfileJobsList,
  },
  mixins: [messagesMixin],
  props: {
    hasProfileViewAccess: {
      type: Boolean,
      default: null,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    applicant: {
      type: Boolean,
      default: null,
    },
    isEditProfile: {
      type: Boolean,
      default: false,
    },
  },
  name: "profile",
  data() {
    return {
      user: this.$store.state.user,
      profile: undefined,
      emptyMessage: "Update your profile to add details",
      edit: undefined,
      loading: false,
      hide: true,
      lastActivity: null,
    }
  },
  async created() {
    await this.fetchProfile()
  },
  computed: {
    shouldDisplayHandsOnExperience() {
      const { hasHandsOnExperience, handsOnExperiences } =
        this.profile?.jobSeeker ?? {}
      return hasHandsOnExperience && handsOnExperiences?.length > 0
    },
    jobseekerCompanyView() {
      const isJobSeekerView = !!this.$store.state.userProfile?.jobSeeker
      const isProviderProfile = !!this.profile?.provider
      return isJobSeekerView && isProviderProfile
    },
    companyJobseekerView() {
      console.log(this.applicant)
      // Check if the prop is defined
      if (this.applicant !== null && this.applicant !== undefined) {
        return this.applicant
      } else {
        const isProviderView = !!this.$store.state.userProfile?.provider
        const isJobseekerProfile = !!this.profile?.jobSeeker
        return isProviderView && isJobseekerProfile
      }
    },
    hasAccessToProfile() {
      console.log(this.hasProfileViewAccess)
      if (
        this.hasProfileViewAccess !== null &&
        this.hasProfileViewAccess !== undefined
      ) {
        return this.hasProfileViewAccess
      } else if (this.companyJobseekerView) {
        let profileId = this.profile?._id,
          unlockedApplicants =
            this.$store.state.userProfile?.provider.unlockedApplicants || []

        let isUnlockedProfile = unlockedApplicants.find(
          (item) => item.profile === profileId
        )

        return !isUnlockedProfile ? false : true
      } else {
        return false
      }
    },
  },
  methods: {
    async fetchProfile() {
      const profileId = this.$router.currentRoute.params?.profileId
      const notificationId = this.$router.currentRoute.params?.notificationId
      try {
        if (!profileId && !notificationId) {
          const userProfile = this.$store.state.userProfile
          if (profileId == userProfile.id) {
            this.hide = false
          }
          if (userProfile) {
            this.profile = userProfile
            this.lastActivity = moment(userProfile.lastActivity).fromNow()
          } else {
            const userId = this.$store.state.user?._id
            this.fetchUserProfile(userId)
          }
        } else if (notificationId) {
          for (let j of this.$store.state.userProfile.profilesRequested) {
            if (j._id == notificationId) {
              await this.fetchProfileById(j.profile)
              return
            }
          }
        } else {
          await this.fetchProfileById(profileId)
        }
      } catch (error) {
        this.showErrorMessage(
          error,
          "We couldn't retrieve the data for this profile."
        )
      }
    },
    async fetchUserProfile(userId) {
      const type = this.$store.state.profileType
        ? this.$store.state.profileType
        : "jobSeeker"
      const apiUrl =
        type == "jobSeeker" ? "api/getUserProfile" : "api/getCompanyUserProfile"
      const { data } = await this.$store.dispatch(apiUrl, {
        userId,
      })
      this.profile = data
    },
    async fetchProfileById(profileId) {
      const type = this.$router.currentRoute.params.type
        ? this.$router.currentRoute.params.type
        : "jobSeeker"
      const apiUrl =
        type == "jobSeeker" ? "api/getProfile" : "api/getCompanyProfile"
      const { data } = await this.$store.dispatch(apiUrl, {
        profileId,
      })
      this.profile = data
      this.lastActivity = moment(data.lastActivity).fromNow()
      // TODO: Check if skilled worker and if current user has access, set redacted if sw and no access
      // this.profile.redacted = data.redacted !== null && data.redacted !== undefined ? data.redacted : true
    },
    handleEditClick(section) {
      this.edit = section
      this.$bvModal.show("profile-edit-modal")
    },
    handlePdfOpen(resume) {
      const { name } = this.profile
      const fileName = `${name} Resume.pdf`
      saveAs(resume, fileName)
    },
    setLoading(loading) {
      this.loading = !!loading
    },
    async handleUpdateComplete() {
      this.loading = false
      this.edit = undefined
      await this.fetchProfile()
    },
    async handleExperiencesChange(experiences) {
      this.profile.jobSeeker.handsOnExperiences = experiences
      await this.$store.dispatch("api/updateProfile", {
        profileId: this.profile._id,
        data: this.profile,
      })
      this.$refs.toastMessage.showToast(
        "Skill experiences updated successfully",
        "success"
      )
      await this.fetchProfile()
    },
  },
}
</script>
<style lang="scss">
.user-profile {
  .edit-button {
    position: relative;
  }

  .row {
    margin: 0;
  }
}
</style>
