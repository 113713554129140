<template>
  <span class="redirect-link" @click="to ? $router.push(to) : $router.go(-1)">
    <PhArrowLeft color="#0095E2" size="18" />
    {{ title }}
  </span>
</template>

<script>
import { PhArrowLeft } from "phosphor-vue"

export default {
  components: {
    PhArrowLeft,
  },
  props: {
    title: {
      type: String,
      default: "Back to previous page",
    },
    to: {
      type: String,
      required: false,
    },
  },
}
</script>

<style scoped>
.redirect-link {
  color: var(--gfb-blue-brand-500, #0095e2);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 24px;
  cursor: pointer;
}
</style>
