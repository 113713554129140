export default {
  tier2_categories: [
    "Acoustical & Decorative Ceilings",
    "Appliance Repair",
    "Auto Mechanic",
    "Cabinetry Installation",
    "Cabinet Refacing & Refinishing",
    "Carpentry - Custom Woodworking",
    "Carpentry",
    "Ceramic or Stone Tile Installation",
    "Concrete - Flatwork",
    "Concrete - Stamped & Decorative",
    "Concrete - Leveling",
    "Countertops - Concrete",
    "Countertops - Formica",
    "Countertops - Solid Surface",
    "Countertops - Stone & Marble",
    "Crane Services",
    "Deck Building",
    "Diesel Mechanic",
    "Doors & Windows",
    "Drywall Finishes",
    "EIFS (Exterior Insulation and Finish Systems)",
    "Excavating & Earthwork",
    "Fencing",
    "Fireplace Installation",
    "Flashing & Sheet Metal",
    "Flooring - Carpet",
    "Flooring - Epoxy & Resurfacing",
    "Flooring - Tile",
    "Flooring - Vinyl & LVT",
    "Flooring - Wood & Laminate Wood",
    "Flooring - Wood Refinishing",
    "Forklift Operator",
    "Foundation Repair",
    "Foundation Waterproofing",
    "Foundations - Masonry",
    "Foundations - Poured",
    "Foundations - Slab on Grade",
    "Framing - Metal",
    "Framing - Wood",
    "Garage & Overhead Doors",
    "Glazing",
    "Gutters - Specialty Metals",
    "Gutters & Downspouts",
    "Handyman",
    "Heavy Equipment Operator",
    "Highway & Civil Construction",
    "Home Automation & Audio/Visual Wiring",
    "Insulation",
    "Interior Design & Decorating",
    "Interior Paneling",
    "Irrigation Systems",
    "Landscape Design",
    "Landscaping - Hardscapes",
    "Masonry",
    "Millwright",
    "Ornamental Metals & Fabrication",
    "Painting",
    "Painting & Staining - Custom Finishes",
    "Plastering",
    "Pool Construction",
    "Rigging",
    "Roofing - Asphalt",
    "Roofing - Membrane",
    "Roofing - Metal",
    "Roofing - Slate",
    "Roofing - Tile",
    "Scaffolding",
    "Shower Doors & Glass Panels",
    "Siding - Cement",
    "Siding - Vinyl & Metal",
    "Siding - Wood",
    "Skylights",
    "Stainless Steel Fabrication",
    "Stair Railing Systems",
    "Steel Erection",
    "Structural Metals",
    "Structural Glazing",
    "Stucco Finishes",
    "Trucking & Hauling",
    "Wallpapering",
    "Welding",
    "Wells & Pumps",
  ],
  tier3_categories: [
    "Architects",
    "Boilers & Radiators",
    "Clean Energy - Solar Panels",
    "Clean Energy - Wind Turbines",
    "Clean Energy - Bioenergy",
    "Clean Energy - Clean Vehicles",
    "CNC",
    "Construction Engineering",
    "Construction Management",
    "Construction Estimating",
    "Electrical",
    "Elevators",
    "Fire Suppression Systems",
    "Geothermal Systems",
    "Groundman",
    "Heating & A/C",
    "Ironworkers",
    "Land Surveying",
    "Lineman",
    "Plumbing & Pipefitting",
    "Septic Systems",
    "Sewer & Drain Cleaning",
    "Structural Engineering",
  ],
}
