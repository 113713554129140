var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4"},[_c('b-nav',{staticClass:"my-4 nav-list"},_vm._l((_vm.dynamicNavItems),function(item){return _c('b-nav-item',{key:item.title,staticClass:"mx-2 font-weight-bold",attrs:{"active-class":"border-warning border-top-0 border-left-0 border-right-0 border-bottom","link-classes":"p-0","to":item.to}},[_vm._v(" "+_vm._s(item.title)+" "),(
          item.title == 'Saved Jobs' &&
          _vm.$store.state.userProfile.savedJobs.length
        )?_c('span',{staticClass:"count",attrs:{"id":"saved-jobs-count"}},[_vm._v(" "+_vm._s(_vm.savedJobsTotal)+" ")]):_vm._e(),(item.title == 'Activity')?_c('span',{staticClass:"count",attrs:{"id":"activity-count"}}):_vm._e(),(
          (item.title == 'View Job Briefings' &&
            _vm.hasJobBriefingNotification) ||
          (item.title == 'Post a Project' && _vm.hasProjectNotification) ||
          (item.title == 'Post a Job' && _vm.hasJobApplicationNotification)
        )?_c('div',{staticStyle:{"float":"right","position":"relative","top":"-5px"}},[_c('svg',{attrs:{"width":"13","height":"12","viewBox":"0 0 13 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"6.97656","cy":"6","r":"5","fill":"#FA0000","stroke":"white","stroke-width":"2"}})])]):_vm._e()])}),1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }