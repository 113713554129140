<template>
  <div class="row jobseeker-details" v-if="profile.jobSeeker">
    <RedirectLink />
    <div class="contact-info-wrapper">
      <div class="contact-info">
        <div class="profile-img">
          <img src="/img/icons/user-profile.svg" alt="" />
        </div>
        <div class="contact-info-details">
          <h3>
            {{
              hasProfileViewAccess || !applicant ? `${profile.name} ${profile.lastName}` : "Applicant"
            }}
          </h3>
          <span
            ><PhMapPin weight="fill" size="24px" />{{ profile.city }},
            {{ profile.state }}</span
          >
          <span
            ><PhPhone weight="fill" size="24px" />{{
              hasProfileViewAccess || !applicant
                ? profile.phoneNumber
                : "**********"
            }}</span
          >
          <span
            ><PhEnvelope weight="fill" size="24px" />{{
              hasProfileViewAccess || !applicant ? profile.email : "**********"
            }}</span
          >
        </div>
      </div>
      <div
        class="warning"
        v-if="applicant && !hasProfileViewAccess && isMatchDetailView"
      >
        <div class="warning-text">
          <div class="lock-icon">
            <img src="/img/icons/lock.svg" alt="" />
          </div>
          Unlock to connect with, and access other information about this
          applicant
        </div>
        <DefaultButton
          labelText="Unlock"
          @click="$emit('request-profile-access')"
        />
      </div>
      <div class="update-profile" v-if="isEditProfile">
        <div class="info-text">Edit your profile</div>
        <DefaultButton
          btnClass="apply-btn"
          labelText="Edit Profile"
          @click="$emit('click-update')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from "@/components/DefaultButton"
import RedirectLink from "@/components/RedirectLink"
import { PhMapPin, PhPhone, PhEnvelope } from "phosphor-vue"
export default {
  components: {
    DefaultButton,
    PhMapPin,
    PhPhone,
    PhEnvelope,
    RedirectLink,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    applicant: {
      type: Boolean,
      default: false,
    },
    hasProfileViewAccess: {
      type: Boolean,
      default: false,
    },
    isEditProfile: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    const currentPath = this.$router.history.current.name
    if (currentPath === "jobMatchDetail") {
      this.isMatchDetailView = true
    }
  },
  data() {
    return {
      isMatchDetailView: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.jobseeker-details {
  display: flex;
  padding: 24px 96px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: var(--Gray-50, #f6f7f9);
  margin: 0;

  .contact-info-wrapper {
    display: flex;
    width: 100%;

    .contact-info {
      display: flex;
      width: 100%;
      align-items: center;

      .profile-img {
        width: 104px;
        height: 104px;
        fill: var(--Gray-100, #eceef2);
        border-radius: 50%;
        margin-right: 32px;

        img {
          border-radius: 50%;
        }
      }

      .contact-info-details {
        display: flex;
        flex-direction: column;

        h3 {
          margin-bottom: 6px;
          font-size: 24px;
          font-weight: 700;
        }

        span {
          margin-top: 12px;
          font-size: 14px;

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .warning {
    display: flex;
    padding: 20px 24px 24px 24px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Blue-50, #d6e1f3);
    background: var(--White, #fff);

    .warning-text {
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      .lock-icon {
        margin-right: 16px;

        img {
          max-width: unset !important;
        }
      }
    }

    .button-wrapper {
      width: 100% !important;
    }
  }
}

.update-profile {
  display: flex;
  padding: 20px 24px 24px 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #d5d9e2);
  background: var(--White, #fff);
  min-width: 350px;
  justify-content: center;

  .info-text {
    color: var(--Gray-900, #343946);
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }

  .button-wrapper {
    width: 100% !important;
  }
}
</style>
