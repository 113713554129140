<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar :background-color="sidebarBackground">
      <template slot="links">
        <v-navigation-drawer
          v-model="drawer"
          :mini-variant="mini"
          mobile-breakpoint="1024"
          permanent
        >
          <v-list :class="{ 'mini-list': mini }">
            <v-list-item
              title="Home"
              class="nav-link home-link mini-list-item"
              @click="$router.push('/dashboard')"
              link
            >
              <v-list-item-icon v-if="mini" class="icon-mini">
                <img class="nav-icon" :src="`/img/icons/gfb-logo-mini.svg`" />
                <span class="tooltipText">Home</span>
              </v-list-item-icon>
              <v-list-item-icon v-else class="icon-large">
                <img class="nav-icon" :src="`/img/icons/gfb-logo-large.svg`" />
              </v-list-item-icon>

              <v-list-item-content> </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-btn
            icon
            @click.stop="mini = !mini"
            :class="['toggle-nav', { open: mini }]"
          >
          </v-btn>

          <v-list :class="{ 'mini-list': mini, 'nav-list': true }" dense>
            <v-list-item
              v-for="item in sidebarItems"
              :key="item.title"
              class="nav-link mini-list-item"
              :class="{ 'current-route': (($route.path === item.to) || (Array.isArray(item.children) && item.children.includes($route.path)))
 }"
              @click="$router.push(item.to)"
              link
            >
              <v-list-item-icon>
                <component size="24" v-bind:is="item.icon"></component>
                <span class="tooltipText" v-if="mini">{{ item.tooltip || item.title }}</span>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <div
                  v-if="
                    ((item.title == 'Find employees' ||
                      item.title == 'Find Jobs') &&
                      hasJobBriefingNotification) ||
                    (item.title == 'Find Partners' && hasProjectNotification) ||
                    (item.title == 'Find employees' &&
                      hasJobApplicationNotification)
                  "
                  style="
                    float: right;
                    position: relative;
                    top: -5px;
                    flex: unset;
                  "
                >
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="6.97656"
                      cy="6"
                      r="5"
                      fill="#FA0000"
                      stroke="white"
                      stroke-width="2"
                    />
                  </svg>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              title="Support"
              :key="`support`"
              class="nav-link mini-list-item"
              @click="handleSupportModal()"
              link
            >
              <v-list-item-icon>
                <img class="nav-icon" :src="`/img/icons/Support 1 1.svg`" />
                <span class="tooltipText" v-if="mini">Support</span>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Support</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
              class="mini-list-group-item nav-link"
              :class="{ 'current-route': $route.path == '/toolbox/settings' || $route.path == '/toolbox/billing' }"
              v-if="profileType == 'company'"
              :key="companyItems.title"
              v-model="companyItems.active"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <component
                    size="24"
                    v-bind:is="companyItems.icon"
                  ></component>
                  <span class="tooltipText" v-if="mini">{{
                    companyItems.title
                  }}</span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="companyItems.title"
                  ></v-list-item-title>
                  <v-list-item-subtitle>{{ profileType }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>

              <v-list-item
                class="nav-link mini-list-item"
                :class="{ 'current-route': $route.path == child.to }"
                @click="$router.push(child.to)"
                v-for="child in companyItems.items"
                :key="child.title"
              >
                <v-list-item-icon>
                  <component size="24" v-bind:is="child.icon"></component>
                  <span class="tooltipText" v-if="mini">{{ child.title }}</span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-group
              class="mini-list-group-item nav-link"
              :class="{ 'current-route': $route.path == '/toolbox/settings' || $route.path == '/toolbox/billing' }"
              v-if="profileType == 'jobSeeker'"
              :key="jobSeekerItems.title"
              v-model="jobSeekerItems.active"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <component
                    size="24"
                    v-bind:is="jobSeekerItems.icon"
                  ></component>
                  <span class="tooltipText" v-if="mini">{{
                    jobSeekerItems.title
                  }}</span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="jobSeekerItems.title"
                  ></v-list-item-title>
                  <v-list-item-subtitle>Job Seeker</v-list-item-subtitle>
                </v-list-item-content>
              </template>

              <v-list-item
                class="nav-link mini-list-item"
                :class="{ 'current-route': $route.path == child.to }"
                @click="$router.push(child.to)"
                v-for="child in jobSeekerItems.items"
                :key="child.title"
              >
                <v-list-item-icon>
                  <component size="24" v-bind:is="child.icon"></component>
                  <span class="tooltipText" v-if="mini">{{ child.title }}</span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-item
              title="Logout"
              :key="`logout`"
              class="nav-link mini-list-item"
              @click="handleLogout()"
              link
            >
              <v-list-item-icon>
                <PhSignOut size="24" />
                <span class="tooltipText" v-if="mini">Logout</span>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <navbar :sidebarItems="sidebarItems"></navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>
    <support-modal v-on:send-support-request="sendSupportRequest">
    </support-modal>
  </div>
</template>
<script>
import Navbar from "./Navbar.vue"
import { FadeTransition } from "vue2-transitions"
import SupportModal from "@/components/Modals/SupportModal"
import {
  PhUser,
  PhHandshake,
  PhHouse,
  PhUsers,
  PhBriefcase,
  PhMagnifyingGlass,
  PhSignOut,
  PhGear,
  PhCurrencyDollar,
  PhUserCircle,
} from "phosphor-vue"
import { useRoute } from 'vue-router';

export default {
  components: {
    Navbar,
    FadeTransition,
    SupportModal,
    PhUser,
    PhHandshake,
    PhUsers,
    PhHouse,
    PhBriefcase,
    PhMagnifyingGlass,
    PhSignOut,
    PhGear,
    PhCurrencyDollar,
    PhUserCircle,
  },
  data() {
    const userProfile = this.$store.state.userProfile
    const profileType = userProfile?.jobSeeker ? "jobSeeker" : "company"
    const companyItems = {
      icon: "PhUserCircle",
      active: false,
      items: [
        { title: "Settings", icon: "PhGear", to: "/toolbox/settings" },
        { title: "Billing", icon: "PhCurrencyDollar", to: "/toolbox/billing" },
      ],
      title: userProfile.name,
    }
    const jobSeekerItems = {
      icon: "PhUserCircle",
      active: false,
      items: [{ title: "Settings", icon: "PhGear", to: "/toolbox/settings" }],
      title: `${userProfile.name} ${userProfile.lastName}`,
    }
    const sidebarItems = [
      {
        title: "Dashboard",
        to: "/dashboard",
        icon: "PhHouse",
      },
      ...(profileType === "jobSeeker"
        ? [
            {
              title: "Profile",
              to: "/toolbox/update-profile",
              icon: "PhUser",
            },
            {
              title: "Find Jobs",
              to: "/jobs/search",
              icon: "PhMagnifyingGlass",
            },
            {
              title: "My Jobs",
              to: "/my-jobs/activities",
              icon: "PhBriefcase",
              children: ["/my-jobs/activities","/my-jobs/saved"]
            },
          ]
        : []),
      ...(profileType === "company"
        ? [
            {
              title: "Company Profile",
              to: "/toolbox/update-profile",
              icon: "PhUser",
            },
            {
              title: "Find Partners",
              to: "/trade-partners/projects",
              icon: "PhHandshake",
              children: ["/trade-partners/projects","/trade-partners/search","/trade-partners/search-projects"]
            },
            {
              title: "Find employees",
              to: "/employees/jobs",
              icon: "PhUsers",
              children: ["/employees/jobs","/employees/company-matches"]
            },
          ]
        : []),
    ]
    return {
      profileType,
      sidebarItems,
      companyItems,
      jobSeekerItems,
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary,
      drawer: true,
      mini: true,
      hasJobBriefingNotification:
        this.$store.state.userProfile.type == "jobSeeker" &&
        this.$store.state.userProfile.profilesRequested.findIndex(
          (x) =>
            x.type == "job" && x.profile != this.$store.state.userProfile._id
        ) > -1,
      hasJobApplicationNotification:
        this.$store.state.userProfile.type == "provider" &&
        this.$store.state.userProfile.profilesRequested.findIndex(
          (x) => x.type == "job-application"
        ) > -1,
      hasProjectNotification:
        this.$store.state.userProfile.profilesRequested.findIndex(
          (x) =>
            x.type == "project" &&
            x.profile != this.$store.state.userProfile._id
        ) > -1,
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    handleSupportModal() {
      this.$bvModal.show("support-modal")
    },
    async sendSupportRequest(data) {
      try {
        const profile = this.$store.state.userProfile
        const { id, type } = profile
        await this.$store.dispatch(
          `api/${type == "jobSeeker" ? "sendSupport" : "sendCompanySupport"}`,
          {
            data,
            profileId: id,
          }
        )
        this.$bvModal.hide("support-modal")
        this.$toast.success("Support request sent!")
      } catch (e) {
        this.$toast.error("Error sending support request")
      }
    },
    handleLogout() {
      try {
        this.$store.dispatch("auth/revokeToken")
        localStorage.clear()
        this.$router.push("/login")
      } catch (error) {
        this.showErrorMessage(error, "We couldn't revoke your credentials.")
      }
    },
  },
}
</script>
<style lang="scss">
.wrapper {
  display: flex;
}

.v-list.mini-list {
  position: absolute !important;
}

.mini-list-item,
.mini-list-group-item {
  position: relative !important;
  padding: 8px 12px !important;

  &.current-route {
    background: var(--gfb-blue-brand-70050-opacity, rgba(0, 82, 124, 0.5));
    border-radius: 8px !important;
  }
}

.mini-list-item .tooltipText,
.mini-list-group-item .tooltipText {
  visibility: hidden;
  width: max-content !important;
  height: auto !important;
  background-color: var(--Gray-800, #3a4252);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 999999;
  top: 8px;
  left: 130%;
  font-size: 14px;
  font-weight: 400;
}

.mini-list-group-item .v-list-group__header .tooltipText {
  left: 150%;
}

.mini-list-item .tooltipText::after,
.mini-list-group-item .tooltipText::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent var(--Gray-800, #3a4252) transparent transparent;
}

.mini-list-item:hover .tooltipText,
.mini-list-group-item:hover .tooltipText {
  visibility: visible;
}

.nav-list {
  margin-top: 25px !important;
  top: 75px;
}

.v-list-item__icon svg {
  margin: 0 7px !important;
}

.mini-list-group-item.nav-link {
  display: block !important;

  .v-list-group__header {
    padding: 0 !important;
    color: #fff !important;

    .v-list-item__title,
    .v-list-item__subtitle,
    .v-list-group__header__append-icon i {
      color: #fff !important;
    }

    &:hover {
      &::before {
        opacity: 0;
      }
    }
  }

  .v-list-item__icon svg {
    margin-left: 4px !important;
  }
}

.v-list-group.v-list-group--active {
  .v-list-group__items {
    display: block !important;
  }
}

.v-navigation-drawer--mini-variant {
  .mini-list-item,
  .mini-list-group-item {
    padding: 5px !important;
  }
  .v-list-group {
    .v-list-group__header .v-list-item__icon {
      margin-left: 5px !important;
    }

    .v-list-group__items {
      .v-list-item__icon svg {
        margin: 0 !important;
      }
    }
  }

  .v-list-item__icon.v-list-group__header__append-icon {
    height: 24px !important;
    clip: unset !important;
    top: 3px !important;
    left: 10px !important;
  }
}

@media (max-width: 767.98px) {
  #sidenav-main {
    display: none;
  }
}
</style>
