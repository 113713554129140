<template>
  <div class="profile-section">
    <profile-section-header
      title="Current Job Openings"
      @handle-edit-click="handleEditClick"
      :isEditing="isEditing"
    />
    <div v-if="profile.provider">
      <div class="row">
        <div
          v-for="job in jobList"
          :key="job._id"
          @click="goToJob(job._id)"
          class="col-lg-4 col-md-6"
        >
          <job-card :job="job" />
        </div>
      </div>
      <div class="pagination" v-if="jobList.length">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="limit"
          @click="goToPage"
        ></b-pagination>
      </div>
      <div class="row" v-if="jobList.length == 0">
        <div
          v-if="loadingData"
          class="text-center align-items-center"
          style="margin: auto"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-else>
          <h2 class="ml-3">No Job openings!</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileSectionHeader from "../ProfileSectionHeader.vue"
import JobCard from "@/components/JobCard"

export default {
  name: "company-profile-jobs",
  components: {
    ProfileSectionHeader,
    JobCard,
  },
  data() {
    return {
      jobList: [],
      limit: 9,
      currentPage: 1,
      totalRows: 0,
      pageCount: 0,
      loadingData: false,
    }
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getJobs()
  },
  methods: {
    handleEditClick() {
      this.$emit("handle-edit-click")
    },
    goToPage(page) {
      console.log(page)
      this.currentPage = page
    },
    async getJobs() {
      this.loadingData = true
      try {
        const data = await this.$store.dispatch("api/searchJobsByCompany", {
          limit: this.limit,
          page: this.currentPage,
          companyId: this.profile._id,
        })
        this.jobList = data.data.result
        this.totalRows = data.data.total
        this.pageCount = data.data.pageCount
        this.loadingData = false
      } catch (error) {
        this.loadingData = false
        console.log("error", error)
        this.logDevError(error)
      }
    },
    goToJob(id) {
      this.$router.push("/jobs/" + id)
    },
  },
  watch: {
    currentPage: function () {
      this.getJobs()
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination {
  justify-content: center;
}

.page-item .page-link {
  border-radius: 10px !important;
}
</style>
